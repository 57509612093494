import React, { FC } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { PlanListInteractions } from '../../../types/PlanListFedops';
import List, { ListWidgetProps } from '../../PackagePicker/Widget/List';
import { EmptyStateProps } from '../../PackagePicker/Widget/List/EmptyState';
import { PlanListWidgetContext } from './PlanListWidgetContext';

const Widget: FC<WidgetProps<ListWidgetProps>> = (props) => {
  const { isMobile } = useEnvironment();
  const emptyState: EmptyStateProps = { isInWidget: true };
  const width = props.host.dimensions.width ?? (isMobile ? 320 : 980);
  return (
    <PlanListWidgetContext locale={props.locale} width={width}>
      <List
        {...props}
        dataHook="PlanList-wrapper"
        interactionEnd={PlanListInteractions.WidgetLoaded}
        emptyState={emptyState}
      />
    </PlanListWidgetContext>
  );
};

export default Widget;
