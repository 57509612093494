import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS, VIEWER_HOOKS } from '../../../../../constants';
import { useAdaptedSettings, useDynamicElement, useStylesParams } from '../../../../../hooks';
import { st, classes } from './PlanName.st.css';

interface Props {
  planId: string;
  name: string;
  highlighted: boolean;
}

export const PlanName: React.FC<Props> = ({ planId, name, highlighted }) => {
  const { isMobile } = useEnvironment();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const settings = useAdaptedSettings();
  const { experiments } = useExperiments();
  const { registerRef, heightByPlanId } = useDynamicElement('planName');
  const planNameLinesEnabled = experiments.enabled(EXPERIMENTS.PLAN_NAME_LINES);
  const showPlanName = styles.get(stylesParams.showPlanName);
  const PlanNameTitleTag = settings.get('planTitleHtmlTag') as keyof JSX.IntrinsicElements;
  const ref = React.useMemo(() => registerRef(planId), [planId]);
  const shouldApplyDynamicHeight = !isMobile && planNameLinesEnabled;

  if (!showPlanName) {
    return null;
  }

  return (
    <PlanNameTitleTag
      data-hook={VIEWER_HOOKS.PLAN_NAME}
      aria-describedby={highlighted ? 'ribbon' : undefined}
      className={st(classes.planTitle, { highlighted, mobile: isMobile })}
      style={shouldApplyDynamicHeight ? { height: heightByPlanId.get(planId) } : {}}
    >
      <span
        className={!shouldApplyDynamicHeight ? classes.ellipsis : classes.block}
        ref={shouldApplyDynamicHeight ? ref : undefined}
      >
        {name}
      </span>
    </PlanNameTitleTag>
  );
};
