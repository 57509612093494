import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { VIEWER_HOOKS } from '../../../../../constants';
import { useDynamicElement } from '../../../../../hooks/useDynamicElement';
import { st, classes } from './Tagline.st.css';

interface Props {
  planId: string;
  description: string;
  highlighted: boolean;
}

export const Tagline: React.FC<Props> = ({ planId, description, highlighted }) => {
  const { isMobile } = useEnvironment();
  const { registerRef, heightByPlanId } = useDynamicElement('tagline');
  const shouldUseDynamicTagline = !isMobile;

  const ref = React.useMemo(() => registerRef(planId), [planId]);

  return (
    <div
      data-hook={VIEWER_HOOKS.PLAN_TAGLINE}
      className={st(classes.tagline, { highlighted, mobile: isMobile })}
      style={shouldUseDynamicTagline ? { height: heightByPlanId.get(planId) } : {}}
    >
      <div ref={shouldUseDynamicTagline ? ref : undefined}>{description}</div>
    </div>
  );
};
